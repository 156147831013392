import { PageWrapper } from './wrap-page-element';
import wrapWithProvider from './wrap-with-provider';

export const wrapRootElement = wrapWithProvider;
export const wrapPageElement = PageWrapper;

export const shouldUpdateScroll = ({ routerProps }) => {
  const hash = routerProps.location.hash;
  if (!hash) {
    window.scrollTo(0, 0);
  }
  return false;
};

export const onInitialClientRender = () => {
  const hash = window.location.hash;
  if (!hash) {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }
};
