import * as Sentry from '@sentry/gatsby';

Sentry.init({
  dsn: 'https://b51b5a330ce84d23a03d0af97482ebd6@o80434.ingest.sentry.io/4505477207031808',
  environment: process.env.GATSBY_PROD === 'true' ? 'prod' : 'staging',
  integrations: [new Sentry.BrowserTracing()],
  release: process.env.GATSBY_PROD === 'true' ? process.env.COMMIT_REF : undefined,
  tracesSampleRate: process.env.GATSBY_PROD === 'true' ? 0.6 : 1.0,
  // Ignore errors that come from places that are not Own Up.
  allowUrls: [/https?:\/\/(\S*\.)?ownup\.com/],
  // Ignore errors related to cache invalidation.
  ignoreErrors: [/Loading chunk \d+ failed/, /We couldn't load "\S*"/]
});
