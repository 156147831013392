import { SplitWrapper } from '@rategravity/marketing-components';
import React from 'react';
import { PageView } from './src/components/page-view';

// Pass all props (hence the ...props) to the layout component so it has access to things like pageContext or location
export const PageWrapper = ({ element }) => (
  <React.Fragment>
    <SplitWrapper />
    <PageView>{element}</PageView>
  </React.Fragment>
);
