exports.components = {
  "component---src-create-pages-templates-author-tsx": () => import("./../../../src/create-pages/templates/author.tsx" /* webpackChunkName: "component---src-create-pages-templates-author-tsx" */),
  "component---src-create-pages-templates-blog-post-tsx": () => import("./../../../src/create-pages/templates/blog-post.tsx" /* webpackChunkName: "component---src-create-pages-templates-blog-post-tsx" */),
  "component---src-create-pages-templates-fact-checker-tsx": () => import("./../../../src/create-pages/templates/fact-checker.tsx" /* webpackChunkName: "component---src-create-pages-templates-fact-checker-tsx" */),
  "component---src-create-pages-templates-home-tsx": () => import("./../../../src/create-pages/templates/home.tsx" /* webpackChunkName: "component---src-create-pages-templates-home-tsx" */),
  "component---src-create-pages-templates-tag-tsx": () => import("./../../../src/create-pages/templates/tag.tsx" /* webpackChunkName: "component---src-create-pages-templates-tag-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-post-preview-tsx": () => import("./../../../src/pages/post-preview.tsx" /* webpackChunkName: "component---src-pages-post-preview-tsx" */)
}

