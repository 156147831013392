import { createTheme } from '@material-ui/core';
import { SplitProvider, SplitExperienceProvider } from '@rategravity/marketing-components';
import { createTrackingInstance, TrackingProvider } from '@rategravity/1pt-lib';
import {
  MOSS_50,
  OwnUpComponentLibraryProvider,
  ownUpWhiteTheme
} from '@rategravity/own-up-component-library';
import * as Sentry from '@sentry/gatsby';
import React from 'react';

const activeSplits: string[] = [];

const App = ({ element }: { element: React.ReactNode }) => {
  const theme = createTheme({
    ...ownUpWhiteTheme,
    palette: {
      ...ownUpWhiteTheme.palette,
      background: {
        default: MOSS_50
      }
    }
  });

  const isLocal = typeof window !== 'undefined' && !window.location.origin.includes('.ownup.com');
  const trackingInstance = createTrackingInstance({
    debug: isLocal,
    url: '/events',
    local: isLocal
  });
  if (trackingInstance.getAnonymousId()) {
    Sentry.setUser({ id: trackingInstance.getAnonymousId() });
  }

  return (
    <OwnUpComponentLibraryProvider theme={theme}>
      <TrackingProvider value={trackingInstance}>
        <SplitProvider>
          <SplitExperienceProvider activeSplits={activeSplits}>{element}</SplitExperienceProvider>
        </SplitProvider>
      </TrackingProvider>
    </OwnUpComponentLibraryProvider>
  );
};

export default ({ element }: { element: React.ReactNode }) => <App element={element} />;
